import React from 'react';
import styled from 'styled-components'
import { Link, ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import Layout from './Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CloseButton = styled(Link)`
  position: absolute;
  left: -15px;
  top: -15px;
  font-size: 29px;
  color: #fff;
  background: var(--accent);
  border-radius: 100%;
  width: 40px;
  height: 40px;
  line-height: 31.4px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0,0,0, .32);
  >svg {
    width: 30px !important;
    height: 30px;
  }
`

const ModalLayout = ({ children, ...rest }) => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      modal ? (
        <>
          <CloseButton to={closeTo}>
            <FontAwesomeIcon icon="times" fixedWidth/>
          </CloseButton>
          {children}
        </>
      ) : (
        <Layout { ...rest }>
          {children}
        </Layout>
      )
    )}
  </ModalRoutingContext.Consumer>
)

export default ModalLayout
