import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from "gatsby"
import styled from 'styled-components';
import Img from 'gatsby-image'
import { TechnologyPill } from '../components/SharedStyledComponents'
import ModalLayout from '../components/ModalLayout'
import { checkScrollbars } from '../components/custom-hooks'

const Root = styled.div`
  background: var(--content-bg);
  border-radius: 8px;
  box-shadow: var(--shadow-on-bg);
  border: 2px solid var(--input-border-dark);
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
  >h1 {
    margin: 0;
  }
  h2 {
    margin: 0;
    font-weight: 400;
    font-size: 25px;
    text-align: center;
    margin: 20px 0;
  }
  >hr {
    width: 100%;
    height: 4px;
    background: var(--input-border);
    border: none;
    border-radius: 4px;
    margin: 20px 0 0;
  }
  >.project-content {
    overflow-y: hidden;
    padding: 20px;
    height: 100%;
  }
  @media (min-width: 600px) {
    >.project-content {
      padding: 40px;
    }
  }
`

const Information = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  h1 {
    font-weight: 400;
    font-size: 30px;
    text-align: center;
    margin: 20px 0 0;
  }
  @media (min-width: 600px) {
    margin: 0;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;
    h1 {
      margin: 0;
      text-align: left;
      font-size: 35px;
    }
  }
`

const ProjectPicture = styled(Img)`
  max-width: 200px;
  width: 70%;
  max-height: 200px;
  height: 50%;
  box-shadow: var(--shadow-on-bg);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
  @media (min-width: 425px) {
    width: 50%;
  }
  @media (min-width: 600px) {
    max-width: 200px;
    width: 200px;
    max-height: 200px;
    height: 200px;
    margin-right: 40px;
  }
  @media (min-width: 992px) {
    max-width: 250px;
    width: 250px;
    max-height: 250px;
    height: 250px;
  }
`

const Content = styled.div`
  p, li {
    font-weight: 300;
    font-size: 18px;
    line-height: 1.5;
    text-align: justify;
  }
  li {
    margin: 0;
  }
  h2 {
    text-align: left;
    line-height: 1.5;
    font-weight: 400;
  }
  a {
    color: var(--accent);
  }
  .gatsby-resp-iframe-wrapper {
    
  }
  * {
    margin-bottom: 20px;
    margin-top: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const TechnologiesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  flex-basis: 20px;
  margin-top: 10px;
  max-width: 250px;
  @media (min-width: 600px) {
    justify-content: flex-start;
  }
`

const ProjectContent = styled.div`
  overflow-y: auto;
`

export default function ProjectPage({ data }) {
  const { markdownRemark: project } = data;
  const customScrollbars = checkScrollbars();
  return (
    <ModalLayout>
      <Helmet title={`lorenzopr. - ${project.frontmatter.title}`} />
      <Root>
        <ProjectContent className={`project-content ${customScrollbars} on-white`}>
          <Information>
            <ProjectPicture fluid={project.frontmatter.thumbnail.childImageSharp.fluid}/>
            <div>
              <h1>{project.frontmatter.title}</h1>
              <TechnologiesContainer>
                {project.frontmatter.technologies.map((tech, index) => (
                  <TechnologyPill key={index}>{tech}</TechnologyPill>
                ))}
              </TechnologiesContainer>
            </div>
          </Information>
          <hr/>
          <Content dangerouslySetInnerHTML={{ __html: project.html }} />
        </ProjectContent>
      </Root>
    </ModalLayout>
  );
}

export const pageQuery = graphql`
  query ProjectByPath($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        technologies
      }
    }
  }
`
